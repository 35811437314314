<template>
  <component
    :is="path ? 'a' : 'div'"
    class="chip"
    tabindex="0"
    v-bind="bindings"
    :class="{
      selected,
      disabled,
      [`i-${icon}`]: icon.length !== 0,
    }"
    @click.prevent="toggle(false)"
    @keyup.enter="toggle(false)"
  >
    <slot />
    <span
      v-if="iconClose"
      :class="`i-cancel-${selected ? 'white' : 'grey'}${
        disabled ? '-disabled' : ''
      }`"
      @click.stop="toggle(true)"
    />
  </component>
</template>

<script setup lang="ts">
import { defineComponent } from 'vue'
import { useNavigate } from '@/composables/useNavigate'

import type { PropType } from 'vue'
import type { IconName } from '@/utils/icon-types'

defineComponent({ name: 'AChip' })

const props = defineProps({
  icon: {
    type: String as PropType<IconName>,
    default: ''
  },
  iconClose: {
    type: Boolean,
    default: false
  },
  selected: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fullClick: {
    type: Boolean,
    default: false
  },
  path: {
    type: String,
    default: null
  }
})
const emit = defineEmits(['update:selected'])

const { getUrl } = useNavigate()

const bindings = computed(() => {
  const attrs = {} as { href?: string, rel?: string }

  if (props.path?.length) {
    attrs.href = getUrl(props.path)

    if (props.path.includes('_ll')) {
      attrs.rel = 'nofollow'
    }
  }

  return attrs
})

const toggle = (force = false): void => {
  if ((!props.fullClick && !force) || props.disabled) { return }
  emit('update:selected', !props.selected)
}
</script>

<style lang="postcss">
.chip {
  display: inline-flex;
  align-items: center;
  padding: var(--spacer-3xs) var(--spacer-2xs);
  border-radius: var(--border-radius-xs);
  background: var(--color-neutral-200);
  color: var(--color-text-dark);
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &[class*="i-"]::before {
    width: 1rem;
    height: 1rem;
    margin-right: var(--spacer-3xs);
  }

  &.disabled {
    background: var(--color-neutral-300);
    color: var(--color-text-middle);
    cursor: default;

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        background: var(--color-neutral-300);
      }
    }
  }

  &.i-ua::before {
    display: none;
  }

  &.selected {
    background: var(--primary);
    color: var(--primary-content);

    &.disabled {
      background: var(--primary);
      color: var(--primary-focus-middle);

      @media (hover: hover) and (--screen-lg) {
        &:hover {
          background: var(--primary);
          color: var(--primary-focus-middle);
        }
      }
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        background: var(--primary-focus-dark);
      }
    }
  }

  & > span {
    height: 1rem;
    margin-left: var(--spacer-3xs);

    &::before {
      width: 1rem;
      height: 100%;
    }
  }

  @mixin text-sm;

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      background: var(--primary-focus-light);
    }
  }
}
</style>
